import React from "react";
import Box from "@mui/material/Box";

function LandingLayout({
  firstContent,
  secondfContent,
  reverse,
  styles,
  mdReverse,
}) {
  return (
    <Box
      display="flex"
      flexDirection={{
        xs: mdReverse ? "column" : "column-reverse",
        md: reverse ? "row-reverse" : "row",
      }}
      justifyContent={"center"}
      alignItems={"center"}
      width="100%"
      overflow={"hidden"}
      sx={Object.assign(
        {
          boxSizing: "border-box",
          px: { xs: "2rem", sm: "20%", md: "5rem" },
        },
        styles || {}
      )}
      style={{}}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: { xs: "100%", md: "25rem", lg: "35rem" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        {firstContent}
      </Box>
      <Box
        sx={{
          maxWidth: { xs: "100%", sm: "50%" },
          display: "flex",
          height: "100%",
          alignSelf: "center",
          boxSizing: "border-box",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {secondfContent}
      </Box>
    </Box>
  );
}

export default LandingLayout;
