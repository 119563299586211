import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const NavItem = ({ item, handleOpen, spacing }) => {
  return (
    <Button
      size="normal"
      variant={item.variant ? item.variant : "text"}
      color={item.color ? item.color : "primary"}
      key={item.slug}
      className={item.className ? item.className : ""}
      sx={{
        color: item.color,
        fontSize: `${spacing}rem`,
        fontWeight: "bold",
        my: 0.5,
        boxShadow: item.glow ? `0px 0px ${item.glow}` : "",
        padding: "0.2rem 1rem",
        marginLeft: "0.1rem",
      }}
      endIcon={item.endIcon ? item.endIcon : null}
      startIcon={item.startIcon ? item.startIcon : null}
      onClick={item.onClick ? item.onClick : () => handleOpen(item.slug)}
    >
      <span style={{ display: "flex", flexDirection: "column" }}>
        <Typography
          variant="p"
          sx={{
            color: "white",
            fontWeight: "bold",
          }}
        >
          {item.title}
        </Typography>
        {item.subtitle && (
          <Typography
            variant="body2"
            color="initial"
            sx={{
              mt: -1,
              color: "white",
              fontSize: "0.7rem",
            }}
            //style={item?.chipStyle || ''}
          >
            {item.subtitle}
          </Typography>
        )}
      </span>
    </Button>
  );
};

export default NavItem;
