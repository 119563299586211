import { Fragment } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import BundleItem from "./bundleItem";
import { Box } from "@mui/material";
import IframeResizer from "iframe-resizer-react";
import { useTranslation } from "react-i18next";
import CurrencySelect from "./CurrencySelect";

export default function PricingContainer({ bundleArray }) {
  const { t } = useTranslation();

  return (
    <Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      {/* Hero unit */}
      <Container
        disableGutters
        maxWidth="sm"
        component="main"
        sx={{ pt: 4, pb: 6 }}
        id="packages"
      >
        <Typography
          component="h1"
          variant="h2"
          align="center"
          gutterBottom
          color={"black"}
          fontWeight={"bold"}
        >
          {t("landing.pricing")}
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 2,
          }}
        >
          <Typography
            variant="h6"
            align="center"
            color="black"
            component="p"
            sx={{ fontWeight: "bold", fontSize: "1.2rem", pt: 2 }}
          >
            {t("landing.noSubscription")}
          </Typography>
        </Box>
      </Container>
      {/* End hero unit */}
      <Container
        maxWidth="lg"
        component="main"
        sx={{
          pb: 6,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid container spacing={3} alignItems="center">
          {bundleArray.map((bundleItem) => (
            <BundleItem
              title={bundleItem.title}
              quantity={bundleItem.quantity}
              subheader={bundleItem.subheader}
              price={bundleItem.price}
              description={bundleItem.description}
              buttonText={bundleItem.buttonText}
              highlighted={bundleItem.highlighted}
              action={bundleItem.action}
              key={bundleItem.quantity}
            />
          ))}
        </Grid>

        <Typography
          variant="h6"
          align="center"
          color="black"
          component="p"
          sx={{ fontWeight: "bold", fontSize: "1rem", pt: 2 }}
        >
          {t("landing.pricingSubtext")}
        </Typography>

        <CurrencySelect color="#3535ff" />

        <Box
          sx={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            mt: 4,
          }}
        >
          <IframeResizer
            src="https://embed-v2.testimonial.to/t/-No0FBc5KcYSTeBCTPQw?design=left-aligned&fontFamily=Inter&fontColor=000000&bgColor=f5f5f5&borderColor=ABB8C3&starColor=FDCC0D&shadowSize=shadow-none&showBorder=no&showPadding=yes&borderRadius=rounded-lg&borderWidth=border"
            style={{ width: "1px", minWidth: "100%", border: "none" }}
          />
        </Box>
      </Container>
      {/* End footer */}
    </Fragment>
  );
}
