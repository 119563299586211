import React from "react";
import IconButton from "@mui/material/IconButton";
import SvgIcon from "@mui/material/SvgIcon";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

function InstagramIcon(props) {
  return (
    <SvgIcon fontSize="inherit" viewBox="0 0 16 16" {...props}>
      <path
        d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"
        fill="#000000"
      ></path>
    </SvgIcon>
  );
}

function TikTokIcon(props) {
  return (
    <SvgIcon fontSize="inherit" viewBox="0 0 16 16" {...props}>
      <path
        d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z"
        fill="#000000"
      ></path>
    </SvgIcon>
  );
}

function LandingSocials() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        width: "100%",
        paddingTop: 3,
        paddingBottom: 7,
      }}
      id={"socials"}
    >
      <Typography
        variant="h3"
        fontWeight={"bold"}
        sx={{
          color: "#000",
          backgroundColor: "white",
          textAlign: "center",
          py: 5,
          px: 2,
          borderTop: "1px solid #dcdcdc",
        }}
      >
        {t("landing.socials")}
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          mt: 1,
        }}
      >
        <IconButton
          color="primary"
          component="a"
          href="https://instagram.com/phototag.ai"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            fontSize: "4rem",
            padding: (theme) => theme.spacing(3),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <InstagramIcon />
        </IconButton>

        <IconButton
          color="primary"
          component="a"
          href="https://tiktok.com/@phototag.ai"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            fontSize: "4rem",
            padding: (theme) => theme.spacing(3),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TikTokIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

export default LandingSocials;
