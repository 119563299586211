import React, { Fragment, useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { NavbarContext } from "../context/NavbarContext";

function Survey() {
  const navbar = useContext(NavbarContext);

  const handleOpen = (slug) => {
    navbar.setSelectedNavItem(slug);
    navbar.setOpenNavModal(true);
  };

  return (
    <Fragment>
      <Box
        sx={{
          backgroundImage: {
            lg: "linear-gradient(to right, rgba(255,255,255,1) 30%, rgba(255,255,255,0.7) 60%), url(https://images.unsplash.com/photo-1674673353760-c86fdc093a56?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1932&q=80)",
            xs: "linear-gradient(to bottom, rgba(255, 255, 255,1) 0%, rgba(255, 255, 255,0.7) 100%), url(https://images.unsplash.com/photo-1674673353760-c86fdc093a56?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1932&q=80)",
          },
          backgroundColor: "white",
          backgroundSize: "cover",
          backgroundPosition: "right",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: { xs: "90vw", md: "200vw" },
          }}
        >
          <Typography
            variant="h2"
            fontWeight="bold"
            sx={{ color: "#000", textAlign: "center" }}
          >
            Thanks for completing the survey
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: "#000",
              maxWidth: "60%",
              textAlign: "center",
              mb: 2,
            }}
          >
            Use promotion code{" "}
            <span style={{ fontWeight: "bold" }}>SURVEY23</span> on your next
            one-time purchase of a 1,000 or 10,000 photo bundle for 15% off.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleOpen("more-photos");
            }}
            sx={{
              padding: "16px 32px",
              fontSize: "1.2rem",
              fontWeight: "bold",
            }}
          >
            Redeem now
          </Button>
        </Box>
      </Box>
    </Fragment>
  );
}

export default Survey;
