import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit"; //import downloadCsv from 'download-csv';
import { useState } from "react";
import TextField from "@mui/material/TextField";
import UndoIcon from "@mui/icons-material/Undo";
const ExportOption = ({
  selectedValues,
  index,
  options,
  field,
  handleDropdownChange,
}) => {
  const [customValue, setCustomValue] = useState(false);

  return (
    <FormControl
      sx={{
        m: 1,
        minWidth: 120,
        backgroundColor: "#2E0809",
        borderRadius: "2rem",
        "&:hover": {
          border: "none !important",
        },
      }}
      size="large"
    >
      <Box
        sx={{
          display: "flex",
        }}
      >
        <IconButton
          aria-label="delete"
          color="primary"
          onClick={() => setCustomValue((prev) => !prev)}
          sx={{
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          {!customValue ? <EditIcon /> : <UndoIcon />}
        </IconButton>
        {!customValue ? (
          <Box>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={selectedValues[index] || options[index]?.options[0]?.value}
              onChange={(e) => handleDropdownChange(index, e.target.value)}
              variant="outlined"
              sx={{
                color: "#ff3a5c",
                fontWeight: "bold",
                outline: "none !important",
                fontSize: "0.89rem",
                "&:hover": {
                  border: "none",
                },
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
              }}
            >
              {field?.options.map((option, index) => (
                <MenuItem key={index} value={option?.value}>
                  {option?.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
        ) : (
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            label={"Custom value"}
            value={selectedValues[index] || options[index]?.options[0]?.value}
            onChange={(e) => handleDropdownChange(index, e.target.value)}
            suppressContentEditableWarning={true}
            sx={{
              width: "100%",
              color: "#dcdcdc !important",
              fieldset: {
                borderColor: "hsla(0,0%,100%,0)",
                transition: "all .2s ease-out",
                borderRadius: "2rem !important",
                "&:hover": {
                  borderColor: "red !important",
                },
              },
              input: {
                borderColor: "hsla(0,0%,100%,.1)",
                transition: "all .2s ease-out",
              },
            }}
          />
        )}
      </Box>
    </FormControl>
  );
};
export default ExportOption;
