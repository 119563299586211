import React, { useState, useEffect, Fragment } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

function Blog() {
  const { t } = useTranslation();
  const [blogPosts, setBlogPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 12;

  useEffect(() => {
    fetch(
      `${
        process.env.REACT_APP_BLOG_URL
      }/api/blogs?sort=Date:desc&pagination[start]=${
        currentPage * postsPerPage - postsPerPage
      }&pagination[limit]=${postsPerPage}&populate=*`
    )
      .then((res) => res.json())
      .then((res) => setBlogPosts(res.data ?? []));
  }, [currentPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      window.scrollTo(0, 0);
    }
  };

  const nextPage = () => {
    if (blogPosts.length === postsPerPage) {
      setCurrentPage((prevPage) => prevPage + 1);
      window.scrollTo(0, 0);
    }
  };

  return (
    <Fragment>
      <Helmet>
        <title>PhotoTag.ai - {t("blogHelmet.headingSubtext")}</title>
        <meta name="description" content={t("blogHelmet.headingDescription")} />
        <meta
          property="og:title"
          content={"PhotoTag.ai - " + t("blogHelmet.headingSubtext")}
        />
        <meta
          property="og:description"
          content={t("blogHelmet.headingDescription")}
        />
        <meta
          property="og:image"
          content="https://login.phototag.ai/storage/v1/object/public/assets/thumbnail.jpg"
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:site_name" content="PhotoTag.ai" />
        <meta property="og:type" content="website" />
        <meta
          name="twitter:title"
          content={"PhotoTag.ai - " + t("blogHelmet.headingSubtext")}
        />
        <meta
          name="twitter:description"
          content={t("blogHelmet.headingDescription")}
        />
        <meta
          name="twitter:image"
          content="https://login.phototag.ai/storage/v1/object/public/assets/thumbnail.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "${window.location.href}"
              },
              "headline": "PhotoTag.ai - ${t("blogHelmet.headingSubtext")}",
              "description": "${t("blogHelmet.headingDescription")}",
              "image": "https://login.phototag.ai/storage/v1/object/public/assets/thumbnail.jpg",
              "publisher": {
                "@type": "Organization",
                "name": "PhotoTag.ai",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://phototag.ai/assets/logo.png"
                }
              }
            }
          `}
        </script>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Box
        sx={{
          backgroundColor: "#f0f4f8", // Soft blueish-gray
          display: "flex",
          justifyContent: "center",
          width: "100%",
          gap: "2rem", // Adjusted for spacing between blog posts
          paddingTop: "9rem",
          minHeight: "100vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            maxWidth: "1200px", // This will ensure content doesn't stretch too wide on very large screens
            gap: "1.5rem",
            height: "100%",
            marginLeft: "auto", // Center the box with auto margins
            marginRight: "auto",
            paddingLeft: "1rem", // Add left and right padding
            paddingRight: "1rem",
          }}
        >
          <Link
            to={`/blog`}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Typography
              variant="h2"
              fontWeight="bold"
              sx={{ color: "#000", textAlign: "center" }}
            >
              Blog
            </Typography>
          </Link>
          {blogPosts.length === 0 ? (
            <Typography
              variant="h4"
              fontWeight="bold"
              marginTop="15vh"
              marginBottom="15vh"
              sx={{ color: "#000", textAlign: "center" }}
            >
              Loading...
            </Typography>
          ) : (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))", // A responsive grid layout
                gap: "1.5rem",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {blogPosts.map((blogPost) => (
                <Link
                  key={blogPost?.id}
                  to={`/blog/${blogPost?.attributes?.Slug}`}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "1rem",
                      border: "3px solid #e0e0e0", // Border for each blog post
                      borderRadius: "8px", // Rounded corners
                    }}
                  >
                    <Typography
                      variant="h5"
                      fontWeight="bold"
                      sx={{ color: "#000", textAlign: "center" }}
                    >
                      {blogPost?.attributes?.Title}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#000",
                        textAlign: "center",
                        fontSize: "1rem",
                        mb: 2,
                      }}
                    >
                      {blogPost?.attributes?.Date}
                    </Typography>
                    <img
                      src={`${blogPost?.attributes?.Photo?.data?.attributes?.formats?.small?.url}`}
                      alt={blogPost?.attributes?.Title}
                      style={{
                        maxWidth: "50%", // Ensure the image doesn't overflow
                        borderRadius: "8px", // Rounded corners
                      }}
                    />
                  </Box>
                </Link>
              ))}
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "1rem",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={previousPage}
              disabled={currentPage === 1}
              sx={{
                margin: "2rem 0",
              }}
            >
              {t("blog.previousPage")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={nextPage}
              disabled={blogPosts.length < postsPerPage}
              sx={{
                margin: "2rem 0",
              }}
            >
              {t("blog.nextPage")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
}

export default Blog;
