import { Fragment } from "react";
import Navbar from "../components/Navbar";
export const AppLayout = ({ supabase, children }) => {
  return (
    <Fragment>
      <Navbar supabase={supabase} />
      <Fragment>{children}</Fragment>
    </Fragment>
  );
};

export default AppLayout;
