import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Fragment, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { useTranslation } from "react-i18next";

const ChangePassword = ({ session }) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordTooShort, setPasswordTooShort] = useState(false);
  const [passwordsDontMatch, setPasswordsDontMatch] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showSubmit, setShowSubmit] = useState(true);

  const handlePasswordChange = async (password) => {
    setPasswordTooShort(false);
    setPasswordsDontMatch(false);
    setAuthError(false);
    const email = session?.user?.email;
    if (!email) {
      setAuthError(true);
    } else if (password?.length < 8) {
      setPasswordTooShort(true);
    } else if (password !== confirmPassword) {
      setPasswordsDontMatch(true);
    } else {
      setShowSubmit(false);
      const err = await session.changePassword(email, password);
      if (err) {
        setAuthError(true);
      } else {
        setSuccess(true);
      }
      setShowSubmit(true);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handlePasswordChange(password);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-around",
        flexDirection: "column",
        alignItems: "center",
        width: { xs: "100%", lg: "25rem" },
        height: "22rem",
        flexGrow: 1,
      }}
    >
      <Box>
        <Typography
          variant="h4"
          color="initial"
          fontWeight="bold"
          align="center"
        >
          {t("change.changePassword")}
        </Typography>
      </Box>

      {success ? (
        <Fragment>
          <Typography
            variant="h4"
            color="initial"
            align="center"
            sx={{
              fontWeight: "bold",
            }}
          >
            {t("login.success")}
          </Typography>
        </Fragment>
      ) : session?.user ? (
        <Fragment>
          <TextField
            id="outlined-basic"
            label={t("login.password")}
            variant="outlined"
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyDown}
            InputLabelProps={{
              style: {
                color: password ? "black" : "rgba(0, 0, 0, 0.54)",
              },
            }}
            sx={{
              width: "100%",
              label: {
                color: "black",
              },
              input: {
                color: "black",
              },
              ".MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "black",
                  borderWidth: "2px",
                },
                "&:hover fieldset": {
                  borderColor: "black",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "black",
                },
              },
            }}
          />
          {passwordTooShort && (
            <Typography
              variant="body2"
              color="initial"
              align="center"
              sx={{
                fontWeight: "bold",
                color: "red",
              }}
            >
              {t("login.passwordCheck")}
            </Typography>
          )}
          <TextField
            id="outlined-basic"
            label={t("login.confirmPassword")}
            variant="outlined"
            value={confirmPassword}
            type="password"
            onChange={(e) => setConfirmPassword(e.target.value)}
            onKeyDown={handleKeyDown}
            InputLabelProps={{
              style: {
                color: confirmPassword ? "black" : "rgba(0, 0, 0, 0.54)",
              },
            }}
            sx={{
              width: "100%",
              label: {
                color: "black",
              },
              input: {
                color: "black",
              },
              ".MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "black",
                  borderWidth: "2px",
                },
                "&:hover fieldset": {
                  borderColor: "black",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "black",
                },
              },
            }}
          />
          {passwordsDontMatch && (
            <Typography
              variant="body2"
              color="initial"
              align="center"
              sx={{
                fontWeight: "bold",
                color: "red",
              }}
            >
              {t("login.passwordMatchCheck")}
            </Typography>
          )}

          {authError && (
            <Typography
              variant="body2"
              color="initial"
              align="center"
              sx={{
                fontWeight: "bold",
                color: "red",
              }}
            >
              {t("login.changePasswordError")}
            </Typography>
          )}
          {showSubmit ? (
            <Button
              variant="contained"
              fullWidth
              onClick={async () => handlePasswordChange(password)}
              sx={{
                alignSelf: "center",
                fontWeight: "bold",
                backgroundColor: "black",
                color: "white",
                "&:hover": {
                  backgroundColor: "#333333",
                },
                maxWidth: "10rem",
              }}
            >
              {t("login.submit")}
            </Button>
          ) : (
            <ClipLoader color="rgba(0,0,0,.6)" size={35} />
          )}
        </Fragment>
      ) : (
        <Typography
          variant="body2"
          color="initial"
          align="center"
          sx={{
            fontWeight: "bold",
            color: "red",
          }}
        >
          {t("login.changePasswordError")}
        </Typography>
      )}
    </Box>
  );
};

export default ChangePassword;
