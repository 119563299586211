import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { useTranslation } from "react-i18next";

function LandingFooter() {
  const { t } = useTranslation();

  const features = [
    { name: t("landing.privacy"), href: "/privacy" },
    { name: t("landing.service"), href: "/tos" },
    { name: t("landing.use"), href: "/aup" },
    { name: t("landing.refund"), href: "/refund-policy" },
    {
      name: t("landing.camera"),
      href: "https://www.flaticon.com/free-icons/camera",
    },
  ];

  return (
    <Box
      sx={{
        backgroundColor: "#dcdcdc",
        borderTop: "1px solid #dcdcdc",
        paddingBottom: { xs: "1rem", md: "0rem" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: { xs: "1rem", md: "3rem" },
          py: 5,
        }}
      >
        {features.map((feature, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              justifyContent: "flex-start",
              alignItems: "center",
              maxWidth: "35rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                alignItems: "center",
              }}
            >
              <Link href={feature.href} target="_blank" underline="hover">
                <Typography
                  variant="body2"
                  color="initial"
                  sx={{ color: "black", textAlign: "center" }}
                >
                  {feature.name}
                </Typography>
              </Link>
            </Box>
          </Box>
        ))}
      </Box>
      <Typography
        variant="body2"
        fontWeight={"bold"}
        color="initial"
        sx={{ color: "black", textAlign: "center", mb: 5 }}
      >
        CodeConda LLC
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      ></Box>
    </Box>
  );
}

export default LandingFooter;
