import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Fragment, useState } from "react";
import { LinearProgress } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { useTranslation } from "react-i18next";

const DeleteAlbumModal = ({ supabase, albums, handleClose, session }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState("idle");

  const deleteAlbums = async (e) => {
    e.preventDefault();
    setLoading(true);

    let albumsToDelete = albums;

    try {
      const {
        data: { session: supabaseSession },
      } = await supabase.auth.getSession();
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/delete-albums`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${supabaseSession.access_token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            albums: albumsToDelete,
          }),
        }
      );
      const json = await response.json();

      const { error } = json;

      if (!error) {
        let loadingDelay = 10000 * albumsToDelete?.length;
        await new Promise((resolve) => setTimeout(resolve, loadingDelay));
        setDeleteStatus(null);
      } else {
        setDeleteStatus(t("albums.failed", { count: error }));
      }
    } catch (error) {
      setDeleteStatus(t("albums.tryAgain"));
    }

    let delay = 2000;

    setTimeout(() => {
      handleClose();
      window.location.reload();
    }, delay);

    setTimeout(() => {
      setDeleteStatus("idle");
    }, delay + 500);

    setLoading(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "center",
        width: { xs: "100%", lg: "25rem" },
        flexGrow: 1,
      }}
    >
      {deleteStatus === "idle" ? (
        <Fragment>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "1rem",
              mb: 8,
            }}
          >
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{
                color: "#FCFCFC",
              }}
            >
              {t("albums.deleteAlbum", { count: albums?.length })}
            </Typography>
            <Typography
              variant="body1"
              fontWeight={"bold"}
              color="deepskyblue"
              align="center"
              sx={{
                width: "100%",
                padding: { xs: "0 2rem" },
                mb: 2,
              }}
            >
              {albums && albums.slice(0, 5).map((album) => "[" + album + "]\n")}
              <br />
              {albums && albums.length > 5 && ` +${albums.length - 5} albums`}
            </Typography>

            <Typography
              variant="body1"
              color="white"
              align="center"
              sx={{
                width: "80%",
                padding: { xs: "0 2rem" },
                mb: 2,
              }}
            >
              {!loading ? t("albums.confirm") : t("albums.deleting")}
            </Typography>
            {loading && (
              <LinearProgress
                variant="buffer"
                value={0}
                valueBuffer={0}
                sx={{
                  borderRadius: "1rem",
                  width: "100%",
                }}
                color={"error"}
              />
            )}
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              gap: "0.5rem",
              position: "sticky",
              bottom: "2rem",
              zIndex: 5,
              boxShadow:
                "inset  0px 1.5rem 0px 2rem #121216, 0px 1.5rem 2rem 3rem #121216",
              mt: "auto",
            }}
          >
            <Button
              fullWidth
              variant="contained"
              color="error"
              sx={{
                fontWeight: "bold",
              }}
              onClick={(e) => deleteAlbums(e)}
              disabled={loading}
            >
              {t("albums.yes")}
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="info"
              sx={{
                fontWeight: "bold",
                bgcolor: "#dcdcdc",
                "&:hover": {
                  bgcolor: "gray",
                },
              }}
              onClick={() => handleClose()}
              disabled={loading}
            >
              {t("albums.no")}
            </Button>
          </Box>
        </Fragment>
      ) : (
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{
              color: "#FCFCFC",
            }}
          >
            {deleteStatus ? t("albums.error") : t("albums.success")}
          </Typography>

          {deleteStatus ? (
            <ErrorIcon color="error" sx={{ fontSize: "5rem" }} />
          ) : (
            <CheckCircleIcon color="success" sx={{ fontSize: "5rem" }} />
          )}

          <Typography
            variant="body1"
            color="white"
            align="center"
            sx={{
              width: "80%",
              padding: { xs: "0 2rem" },
              mb: 2,
            }}
          >
            {deleteStatus
              ? `${t("albums.wrong")}. ${deleteStatus}.`
              : t("albums.successText")}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
export default DeleteAlbumModal;
