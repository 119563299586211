import React, { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import PricingContainer from "./pricingContainer";
import { useTranslation } from "react-i18next";
import { SessionContext } from "../context/SessionContext";

function LandingPricing() {
  const { t } = useTranslation();
  const location = useLocation();
  const session = useContext(SessionContext);

  useEffect(() => {
    if (location.state?.scrollToPricing) {
      const element = document.getElementById("pricing");
      element.scrollIntoView();
    }
  }, [location.state]);

  const freeItem = {
    title: "Free Plan",
    quantity: 10,
    subheader: t("landing.start"),
    description: [
      t("landing.card"),
      t("landing.maxSize", { size: "15MB" }),
      //t("landing.maxVideoSize", { size: "500MB" }),
    ],
    buttonText: t("landing.tryNow"),
    action: "login",
    highlighted: true,
  };

  const features = [
    {
      title: "🚴‍♂️ Basic Plan",
      quantity: [400, 4800],
      //old_quantity: [200, 2400],
      price: ["9.99", "81.99"],
      //old_price: [null, null],
      subheader: t("landing.essentials"),
      description: [
        t("landing.downgrade"),
        t("landing.maxBatch", { batch: "400" }),
        t("landing.maxSize", { size: "40MB" }),
        t("landing.maxVideoSize", { size: "500MB" }),
        //t("landing.languages"),
        //t("landing.csvExport"),
        //t("landing.unlimited"),
        t("landing.trendsAccess"),
      ],
      buttonText: t("landing.subNow"),
      action: "login",
    },
    {
      title: "🚀 Pro Plan",
      quantity: [1500, 18000],
      //old_quantity: [1000, 12000],
      price: ["27.99", "238.99"],
      //old_price: [null, null],
      subheader: t("landing.popular"),
      description: [
        t("landing.downgrade"),
        t("landing.maxBatch", { batch: "1,000" }),
        t("landing.maxSize", { size: "40MB" }),
        t("landing.maxVideoSize", { size: "500MB" }),
        //t("landing.languages"),
        //t("landing.csvExport"),
        //t("landing.unlimited"),
        t("landing.trendsAccess"),
      ],
      buttonText: t("landing.subNow"),
      action: "login",
      highlighted: true,
    },
    {
      title: "🏭 Enterprise Plan",
      quantity: [10000, 120000],
      //old_quantity: [200, 2400],
      price: ["119.99", "999.99"],
      //old_price: [null, null],
      subheader: t("landing.enterprise"),
      description: [
        t("landing.downgrade"),
        t("landing.maxBatch", { batch: "1,000" }),
        t("landing.maxSize", { size: "40MB" }),
        t("landing.maxVideoSize", { size: "500MB" }),
        //t("landing.languages"),
        //t("landing.csvExport"),
        //t("landing.unlimited"),
        t("landing.trendsAccess"),
      ],
      buttonText: t("landing.subNow"),
      action: "login",
    },
  ];

  const bundles = [
    {
      title: "🚴‍♂️ 1,500 Upload Credits",
      quantity: "1,500",
      price: session?.currentCurrency === "EUR" ? "€26" : "$28",
      subheader: t("landing.starter"),
      description: [
        t("landing.onePurchase", { amount: "1,500" }),
        t("landing.maxBatch", { batch: "500" }),
        t("landing.maxSize", { size: "40MB" }),
        t("landing.maxVideoSize", { size: "500MB" }),
        //t("landing.languages"),
        t("landing.neverExpires"),
        //t("landing.limitedUse"),
        t("landing.trendsAccess"),
      ],
      buttonText: t("landing.buyNow"),
      highlighted: false,
      action: "login",
    },
    {
      title: "🚀 10,000 Upload Credits",
      quantity: "10,000",
      price: session?.currentCurrency === "EUR" ? "€110" : "$120",
      subheader: t("landing.popular"),
      description: [
        t("landing.onePurchase", { amount: "10,000" }),
        t("landing.maxBatch", { batch: "1,000" }),
        t("landing.maxSize", { size: "50MB" }),
        t("landing.maxVideoSize", { size: "1GB" }),
        //t("landing.languages"),
        t("landing.neverExpires"),
        //t("landing.limitedUse"),
        t("landing.trendsAccess"),
      ],
      buttonText: t("landing.buyNow"),
      highlighted: true,
      action: "login",
    },
    {
      title: "🏭 100,000 Upload Credits",
      quantity: "100,000",
      price: session?.currentCurrency === "EUR" ? "€780" : "$850",
      subheader: t("landing.power"),
      description: [
        t("landing.onePurchase", { amount: "100,000" }),
        t("landing.maxBatch", { batch: "1,500" }),
        t("landing.maxSize", { size: "60MB" }),
        t("landing.maxVideoSize", { size: "2GB" }),
        //t("landing.languages"),
        t("landing.neverExpires"),
        //t("landing.limitedUse"),
        t("landing.trendsAccess"),
      ],
      buttonText: t("landing.buyNow"),
      highlighted: false,
      action: "login",
    },
  ];

  return (
    <Box
      sx={{
        backgroundRepeat: "repeat",
        borderTop: "1px solid #dcdcdc",
        backgroundColor: "white",
        paddingTop: "3rem",
      }}
      id={"pricing"}
    >
      <PricingContainer bundleArray={bundles} />
    </Box>
  );
}

export default LandingPricing;
