import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { useTranslation } from "react-i18next";

const NewKeywordsControls = ({
  supabase,
  predictions,
  links,
  selectedPhoto,
  handleEditKeywords,
  generating,
  setGenerating,
  session,
  albumId,
  setSnackBarMessage,
  setOpenSnackBar,
}) => {
  const { t } = useTranslation();

  const handleRegenerateKeywords = async (e) => {
    e.preventDefault();
    setGenerating((prev) => [...prev, selectedPhoto]);
    try {
      const {
        data: { session: supabaseSession },
      } = await supabase.auth.getSession();
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/regenerate-keywords`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${supabaseSession.access_token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: {
              description: predictions?.[selectedPhoto]?.data?.[0]?.[1].replace(
                " created with generative AI technology",
                ""
              ),
            },
            user_data: {
              album_id: albumId,
              sub_id: session?.subStatus?.[session?.subStatus?.length - 1]?.id,
            },
          }),
        }
      );
      const json = await response.json();

      const { data, error } = json;
      if (!error) {
        handleEditKeywords(
          "e",
          selectedPhoto,
          { action: "regenerate" },
          data?.[0]?.data?.[1]?.join(", ")?.toString()
        );
        setGenerating((prev) => prev.filter((i) => i !== selectedPhoto));
        const price = session?.actionsPrices?.regenerate_keywords;
        if (session?.planEmpty === true) {
          session?.setBundleCreditsUsed((prev) => prev + price || 1);
        } else {
          session?.setPlanCreditsUsed((prev) => prev + price || 1);
        }
      } else {
        setSnackBarMessage("⚠️ " + error);
        setOpenSnackBar(true);
      }
    } catch (error) {
      setSnackBarMessage(t("editor.serverError"));
      setOpenSnackBar(true);
    }
    setGenerating((prev) => prev.filter((i) => i !== selectedPhoto));
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "1rem",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <span style={{ display: "block", width: "100%" }}>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<AutoFixHighIcon />}
          sx={{ padding: "0.3rem 0.6rem", fontWeight: "bold" }}
          disabled={
            predictions.length < links.length ||
            generating?.includes(selectedPhoto) ||
            predictions?.[selectedPhoto]?.data?.[0]?.[1] === "N/A"
          }
          onClick={(e) => handleRegenerateKeywords(e)}
          style={{}}
          fullWidth
        >
          {t("editor.generateMore")}
        </Button>
      </span>
    </Box>
  );
};

export default NewKeywordsControls;
