import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { useState } from "react";
import { TypeAnimation } from "react-type-animation";
import { useContext } from "react";
import { SessionContext } from "../context/SessionContext";
const CarouselItem = ({
  link,
  predictions,
  index,
  selectedPhoto,
  handleSelect,
  generating,
  blobImages,
}) => {
  const [isLoading, setIsloading] = useState(true);
  const session = useContext(SessionContext);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsloading(false);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        flexWrap: "wrap",
        width: { xs: "8rem", lg: "15rem" },
      }}
      className={
        (predictions.length - 1 === index && generating.includes(index)) ||
        !generating.includes(index)
          ? "completed-img"
          : ""
      }
      key={index}
    >
      <Box
        color="primary"
        sx={{
          width: { xs: "8rem", lg: "15rem" },
          aspectRatio: "16 / 9",
          backgroundColor: "black",
          backgroundImage: `url(${
            blobImages?.[index] || process.env.REACT_APP_LOADING_GIF
          })`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          borderRadius: "1rem",

          opacity: predictions.length - 1 >= index ? "1" : "0.5",
          outline: index === selectedPhoto ? "5px #ff3a5c solid" : "none",
          boxShadow: index === selectedPhoto ? "0px 0px 20px #ff3a5c" : "none",
          "&:hover": {
            outline:
              index !== selectedPhoto && "5px rgba(255,255,255,0.2) solid",
          },
        }}
        onClick={() => handleSelect(index)}
      >
        <Box
          className={
            predictions.length - 1 === index || !generating.includes(index)
              ? ""
              : "animacion-loading"
          }
        ></Box>
      </Box>
      <Typography
        variant="body2"
        sx={{
          color: index === selectedPhoto ? "#dcdcdc" : "#79797e",
          width: "100%",
          fontWeight: "bold",
          overflow: "hidden",
        }}
      >
        {!generating.includes(index) ? (
          isLoading ? (
            <TypeAnimation
              // Same String at the start will only be typed once, initially
              sequence={[predictions?.[index]?.data?.[0]?.[0]]}
              speed={60} // Custom Speed from 1-99 - Default Speed: 40
              wrapper="span" // Animation will be rendered as a <span>
              repeat={0}
              cursor={false} // Repeat this Animation Sequence infinitely
            />
          ) : (
            predictions?.[index]?.data?.[0]?.[0]
          )
        ) : (
          "Generating..."
        )}
      </Typography>
    </Box>
  );
};
export default CarouselItem;
