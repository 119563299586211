import { useTranslation } from "react-i18next";
import { Fragment, useState, useCallback, useEffect } from "react";
import {
  Box,
  Button,
  Snackbar,
  Alert,
  Tooltip,
  IconButton,
  Typography,
  LinearProgress,
} from "@mui/material";
import ModalItem from "./modalItem";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useDropzone } from "react-dropzone";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import CircularProgress from "@mui/material/CircularProgress";

const DownloadFiles = ({ getAlbumData, addMetadata, isMobile }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState("");
  const [albumData, setAlbumData] = useState(null);
  const [albumName, setAlbumName] = useState("");

  useEffect(() => {
    if (getAlbumData && !albumData) {
      getAlbumData().then(({ csv, name }) => {
        setAlbumData(csv);
        setAlbumName(name);
      });
    }
  }, [getAlbumData, albumData]);

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 1500) {
      setError(t("export.tooManyFiles"));
      return;
    }

    const filteredFiles = acceptedFiles.filter(
      (file) => file.type === "image/jpeg" || file.type === "image/png"
    );

    if (filteredFiles.length === 0) {
      setError(t("export.unsupportedFiles"));
      return;
    }

    setFiles(filteredFiles.slice(0, 1500));
  };

  const getMetadata = useCallback(
    (name) => {
      if (!albumData) return null;
      return albumData.find((item) => item.name === name);
    },
    [albumData]
  );

  const processFiles = useCallback(async () => {
    setLoading(true);
    const zip = new JSZip();
    let atLeastOneFileZipped = false;
    let atLeastOneFileError = false;
    let processedFiles = 0;

    const processFile = async (file) => {
      try {
        const metadata = getMetadata(file.name);

        if (metadata) {
          const updatedFile = await addMetadata(
            file,
            metadata.title,
            metadata.description,
            metadata.keywords.join(", "),
            processedFiles + 1,
            files.length
          );
          if (!updatedFile) {
            throw new Error("Error adding metadata to file");
          }
          zip.file(file.name, updatedFile);
          atLeastOneFileZipped = true;
        }
      } catch (err) {
        atLeastOneFileError = true;
        process.env.REACT_APP_SHOW_CONSOLE_ERRORS &&
          console.error(
            `Error processing file ${file.name}:`,
            err?.message || err
          );
      }
    };

    for (const file of files) {
      await processFile(file);
      processedFiles += 1;
      setProgress(Math.floor((processedFiles / files.length) * 100));
    }

    if (!atLeastOneFileZipped) {
      if (atLeastOneFileError) {
        setError(t("export.processingError"));
      } else {
        setError(t("export.noFiles"));
      }
      setLoading(false);
    } else {
      zip.generateAsync({ type: "blob" }).then((content) => {
        saveAs(content, `metadata_${albumName}.zip`);
        setLoading(false);
        setProgress(100);
      });
    }
  }, [files, t, getMetadata, albumName]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png",
    multiple: true,
  });

  const handleCloseError = () => {
    setError("");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
      }}
    >
      <Fragment>
        <ModalItem
          title={
            <>
              {t("export.files")}
              <Tooltip title={t("export.explanation")} arrow>
                <IconButton size="small" sx={{ ml: 1 }}>
                  <HelpOutlineIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </>
          }
          primaryContent={t("export.selectPhotos")}
          dark={true}
        >
          {!isMobile && (
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <Button
                variant="contained"
                endIcon={<UploadFileIcon />}
                sx={{
                  fontWeight: "bold",
                  marginBottom: "1rem",
                }}
                disabled={files.length > 0}
              >
                {files.length === 0
                  ? t("export.uploadPhotos")
                  : `${files.length} ${t("export.filesUploaded")}`}
              </Button>
            </div>
          )}
        </ModalItem>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "0.5rem",
            position: "sticky",
            bottom: "2rem",
            zIndex: 5,
            mt: "auto",
          }}
        >
          <Button
            fullWidth
            variant="contained"
            endIcon={
              loading ? <CircularProgress size={20} /> : <FileDownloadIcon />
            }
            sx={{
              fontWeight: "bold",
            }}
            onClick={processFiles}
            disabled={loading || isMobile || files.length === 0}
          >
            {loading ? t("export.processing") : t("export.downloadFiles")}
          </Button>
        </Box>
        {isMobile && (
          <Typography variant="body1" color="#FCFCFC" textAlign={"center"}>
            {t("export.noMobile")}
          </Typography>
        )}
      </Fragment>
      {loading && (
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{ width: "100%", mb: "1rem" }}
        />
      )}
      {error && (
        <Snackbar
          open={!!error}
          autoHideDuration={6000}
          onClose={handleCloseError}
        >
          <Alert
            onClose={handleCloseError}
            severity="error"
            sx={{ width: "100%" }}
          >
            {error}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default DownloadFiles;
