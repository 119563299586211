import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import GoogleIcon from "@mui/icons-material/Google";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import DraftIcon from "@mui/icons-material/Drafts";
import TextField from "@mui/material/TextField";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Turnstile } from "@marsidev/react-turnstile";
import ClipLoader from "react-spinners/ClipLoader";
import { useTranslation } from "react-i18next";
import { Link } from "@mui/material";
import { blockedTerms } from "../blockedTerms";

const Login = ({ session, navbar }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [captchaToken, setCaptchaToken] = useState("");
  const [logInWithEmail, setLogInWithEmail] = useState(false);
  const [signUpWithEmail, setSignUpWithEmail] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [signUpSuccess, setSignUpSuccess] = useState(false);
  const [forgotSuccess, setForgotSuccess] = useState(false);
  const [emailNotValid, setEmailNotValid] = useState(false);
  const [maxAttemptsReached, setMaxAttemptsReached] = useState(false);
  const [captchaNotCompleted, setCaptchaNotCompleted] = useState(false);
  const [passwordTooShort, setPasswordTooShort] = useState(false);
  const [passwordsDontMatch, setPasswordsDontMatch] = useState(false);
  const [emailInUse, setEmailInUse] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(true);
  const [showSubmit, setShowSubmit] = useState(true);
  const [emailUsed, setEmailUsed] = useState(undefined);
  const [lifetimeEmailSignUps, setLifetimeEmailSignUps] = useState(0);

  useEffect(() => {
    const signUps = localStorage.getItem("ptai-lesu");
    if (signUps) {
      setLifetimeEmailSignUps(parseInt(signUps));
    }
  }, []);

  const incrementLifetimeEmailSignUps = useCallback(() => {
    const newLifetimeEmailSignUps = lifetimeEmailSignUps + 1;
    setLifetimeEmailSignUps(newLifetimeEmailSignUps);
    localStorage.setItem("ptai-lesu", newLifetimeEmailSignUps);
  }, [lifetimeEmailSignUps]);

  const isTempEmail = async (email) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/verify-email`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );
      const json = await response.json();
      if (response.status === 200) {
        return json?.data;
      } else {
        return undefined;
      }
    } catch (error) {
      return undefined;
    }
  };

  const providerAllowsDots = (email) => {
    return (
      email.endsWith("@gmail.com") ||
      email.endsWith("@googlemail.com") ||
      email.endsWith("@protonmail.com")
    );
  };

  const removeDotsFromEmail = (email) => {
    return email.split("@")[0].replace(/\./g, "") + "@" + email.split("@")[1];
  };

  const validateEmail = (email) => {
    if (!email || email.includes("+")) {
      return false;
    }
    const splitEmail = email.split("@");
    if (blockedTerms.some((term) => splitEmail?.[1]?.includes(term))) {
      return false;
    }
    if (email.endsWith(".ru")) {
      if (splitEmail?.[0]?.match(/\d+[a-zA-Z]+/)) {
        return false;
      }
      const allowedDomains = [
        "yandex.ru",
        "mail.ru",
        "list.ru",
        "bk.ru",
        "ya.ru",
      ];
      if (!allowedDomains.includes(splitEmail?.[1])) {
        return false;
      }
    }
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const resetEverything = () => {
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setCaptchaToken("");
    setEmailNotValid(false);
    setMaxAttemptsReached(false);
    setCaptchaNotCompleted(false);
    setPasswordTooShort(false);
    setPasswordsDontMatch(false);
    setEmailInUse(false);
    setAuthError(false);
  };

  const resetCaptcha = () => {
    setCaptchaToken("");
    setShowCaptcha(false);
    setTimeout(() => {
      setShowCaptcha(true);
    }, 100);
  };

  const handleLogin = async (providerName) => {
    session.login(providerName);
  };

  const handleEmailLogin = async (email, password, captcha) => {
    email = email?.trim()?.toLowerCase();
    setEmailNotValid(false);
    setMaxAttemptsReached(false);
    setPasswordTooShort(false);
    setPasswordsDontMatch(false);
    setCaptchaNotCompleted(false);
    setAuthError(false);
    setShowSubmit(false);
    const blocked = await isTempEmail(email);
    if (blocked === undefined) {
      setMaxAttemptsReached(true);
    } else if (blocked === true || !validateEmail(email)) {
      setEmailNotValid(true);
    } else if (password.length < 8) {
      setPasswordTooShort(true);
    } else if (!captchaToken) {
      setCaptchaNotCompleted(true);
    } else {
      const err = await session.loginWithEmail(email, password, captcha);
      if (err) {
        setAuthError(true);
        resetCaptcha();
      } else {
        navbar?.setOpenNavModal(false);
      }
    }

    setShowSubmit(true);
  };

  const handleEmailSignUp = async (email, password, captcha) => {
    email = email?.trim()?.toLowerCase();
    setEmailNotValid(false);
    setMaxAttemptsReached(false);
    setPasswordTooShort(false);
    setPasswordsDontMatch(false);
    setCaptchaNotCompleted(false);
    setEmailInUse(false);
    setAuthError(false);
    setShowSubmit(false);
    const blocked = await isTempEmail(email);
    if (blocked === undefined) {
      setMaxAttemptsReached(true);
    } else if (
      blocked === true ||
      !validateEmail(email) ||
      lifetimeEmailSignUps >= 3
    ) {
      setEmailNotValid(true);
    } else if (password.length < 8) {
      setPasswordTooShort(true);
    } else if (password !== confirmPassword) {
      setPasswordsDontMatch(true);
    } else if (!captchaToken) {
      setCaptchaNotCompleted(true);
    } else {
      const originalEmail = email;
      if (providerAllowsDots(email)) {
        email = removeDotsFromEmail(email);
      }
      const err = await session.signUpWithEmail(email, password, captcha);
      if (err) {
        if (err?.name === "EmailInUseError") {
          setEmailInUse(true);
        } else {
          setAuthError(true);
        }
        resetCaptcha();
      } else {
        setSignUpWithEmail(false);
        setSignUpSuccess(true);
        if (originalEmail !== email) {
          setEmailUsed(email);
        }
        incrementLifetimeEmailSignUps();
      }
    }

    setShowSubmit(true);
  };

  const handleForgotPassword = async (email, captcha) => {
    email = email?.trim()?.toLowerCase();
    setEmailNotValid(false);
    setMaxAttemptsReached(false);
    setCaptchaNotCompleted(false);
    setAuthError(false);
    setShowSubmit(false);
    const blocked = await isTempEmail(email);
    if (blocked === undefined) {
      setMaxAttemptsReached(true);
    } else if (blocked === true || !validateEmail(email)) {
      setEmailNotValid(true);
    } else if (!captchaToken) {
      setCaptchaNotCompleted(true);
    } else {
      const originalEmail = email;
      if (providerAllowsDots(email)) {
        email = removeDotsFromEmail(email);
      }
      const err = await session.forgotPassword(email, captcha);
      if (err) {
        setAuthError(true);
        resetCaptcha();
      } else {
        setForgotPassword(false);
        setForgotSuccess(true);
        if (originalEmail !== email) {
          setEmailUsed(email);
        }
      }
    }

    setShowSubmit(true);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (signUpWithEmail) {
        handleEmailSignUp(email, password, captchaToken);
      } else if (logInWithEmail) {
        handleEmailLogin(email, password, captchaToken);
      } else if (forgotPassword) {
        handleForgotPassword(email, captchaToken);
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-around",
        flexDirection: "column",
        alignItems: "center",
        width: { xs: "100%", lg: "25rem" },
        height: "34rem",
        flexGrow: 1,
      }}
    >
      <Box>
        <Typography
          variant="h4"
          color="initial"
          fontWeight="bold"
          align="center"
          sx={{}}
        >
          {logInWithEmail
            ? t("login.login")
            : forgotPassword || forgotSuccess
            ? t("login.forgot")
            : t("login.login")}
        </Typography>
      </Box>
      {!signUpSuccess && !forgotSuccess && (
        <Typography variant="body2" color="initial" align="center">
          {t("login.start")}
        </Typography>
      )}
      {logInWithEmail && (
        <Fragment>
          <TextField
            id="outlined-basic"
            label={t("login.email")}
            variant="outlined"
            value={email}
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyDown}
            InputLabelProps={{
              style: {
                color: email ? "black" : "rgba(0, 0, 0, 0.54)",
              },
            }}
            sx={{
              width: "100%",
              label: {
                color: "black",
              },
              input: {
                color: "black",
              },
              ".MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "black",
                  borderWidth: "2px",
                },
                "&:hover fieldset": {
                  borderColor: "black",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "black",
                },
              },
            }}
          />
          {emailNotValid && (
            <Typography
              variant="body2"
              color="initial"
              align="center"
              sx={{
                fontWeight: "bold",
                color: "red",
              }}
            >
              {t("login.emailCheck")}
            </Typography>
          )}
          {maxAttemptsReached && (
            <Typography
              variant="body2"
              color="initial"
              align="center"
              sx={{
                fontWeight: "bold",
                color: "red",
              }}
            >
              {t("login.maxAttempts")}
            </Typography>
          )}
          <TextField
            id="outlined-basic"
            label={t("login.password")}
            variant="outlined"
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyDown}
            InputLabelProps={{
              style: {
                color: password ? "black" : "rgba(0, 0, 0, 0.54)",
              },
            }}
            sx={{
              width: "100%",
              label: {
                color: "black",
              },
              input: {
                color: "black",
              },
              ".MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "black",
                  borderWidth: "2px",
                },
                "&:hover fieldset": {
                  borderColor: "black",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "black",
                },
              },
            }}
          />
          {passwordTooShort && (
            <Typography
              variant="body2"
              color="initial"
              align="center"
              sx={{
                fontWeight: "bold",
                color: "red",
              }}
            >
              {t("login.passwordCheck")}
            </Typography>
          )}
          {showCaptcha && (
            <Turnstile
              siteKey="0x4AAAAAAAM1j7tv3O9VOfgu"
              onSuccess={(token) => {
                setCaptchaToken(token);
              }}
            />
          )}
          {captchaNotCompleted && (
            <Typography
              variant="body2"
              color="initial"
              align="center"
              sx={{
                fontWeight: "bold",
                color: "red",
              }}
            >
              {t("login.captchaCheck")}
            </Typography>
          )}
          {authError && (
            <Typography
              variant="body2"
              color="initial"
              align="center"
              sx={{
                fontWeight: "bold",
                color: "red",
              }}
            >
              {t("login.authError")}
            </Typography>
          )}
          {showSubmit ? (
            <Button
              variant="contained"
              fullWidth
              onClick={async () =>
                handleEmailLogin(email, password, captchaToken)
              }
              sx={{
                alignSelf: "center",
                fontWeight: "bold",
                backgroundColor: "black",
                color: "white",
                "&:hover": {
                  backgroundColor: "#333333",
                },
                maxWidth: "10rem",
              }}
            >
              {t("login.submit")}
            </Button>
          ) : (
            <ClipLoader color="rgba(0,0,0,.6)" size={35} />
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 2,
            }}
          >
            <Button
              variant="contained"
              fullWidth
              onClick={async () => {
                setLogInWithEmail(false);
                setSignUpWithEmail(true);
                setForgotPassword(false);
                resetEverything();
              }}
              sx={{
                alignSelf: "center",
                fontWeight: "bold",
                backgroundColor: "transparent",
                color: "black",
                borderColor: "black",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  borderColor: "black",
                },
                minWidth: "10rem",
              }}
            >
              {t("login.emailSignup")}
            </Button>
            <Button
              variant="contained"
              fullWidth
              onClick={async () => {
                setSignUpWithEmail(false);
                setLogInWithEmail(false);
                setForgotPassword(false);
                resetEverything();
              }}
              sx={{
                alignSelf: "center",
                fontWeight: "bold",
                backgroundColor: "orange",
                color: "black",
                borderColor: "black",
                "&:hover": {
                  backgroundColor: "rgba(235, 146, 52, 1)",
                  borderColor: "black",
                },
                minWidth: "10rem",
              }}
            >
              {t("login.anotherWay")}
            </Button>
          </Box>
          <Typography
            variant="body2"
            color="initial"
            align="center"
            fontSize={"0.8rem"}
            sx={{ opacity: "0.7" }}
          >
            <Link
              style={{ cursor: "pointer" }}
              onClick={async () => {
                setSignUpWithEmail(false);
                setLogInWithEmail(false);
                setForgotPassword(true);
                resetEverything();
              }}
            >
              {t("login.forgotPassword")}
            </Link>
          </Typography>
        </Fragment>
      )}
      {forgotPassword && (
        <Fragment>
          <TextField
            id="outlined-basic"
            label={t("login.email")}
            variant="outlined"
            value={email}
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyDown}
            InputLabelProps={{
              style: {
                color: email ? "black" : "rgba(0, 0, 0, 0.54)",
              },
            }}
            sx={{
              width: "100%",
              label: {
                color: "black",
              },
              input: {
                color: "black",
              },
              ".MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "black",
                  borderWidth: "2px",
                },
                "&:hover fieldset": {
                  borderColor: "black",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "black",
                },
              },
            }}
          />
          {emailNotValid && (
            <Typography
              variant="body2"
              color="initial"
              align="center"
              sx={{
                fontWeight: "bold",
                color: "red",
              }}
            >
              {t("login.emailCheck")}
            </Typography>
          )}
          {maxAttemptsReached && (
            <Typography
              variant="body2"
              color="initial"
              align="center"
              sx={{
                fontWeight: "bold",
                color: "red",
              }}
            >
              {t("login.maxAttempts")}
            </Typography>
          )}
          {showCaptcha && (
            <Turnstile
              siteKey="0x4AAAAAAAM1j7tv3O9VOfgu"
              onSuccess={(token) => {
                setCaptchaToken(token);
              }}
            />
          )}
          {captchaNotCompleted && (
            <Typography
              variant="body2"
              color="initial"
              align="center"
              sx={{
                fontWeight: "bold",
                color: "red",
              }}
            >
              {t("login.captchaCheck")}
            </Typography>
          )}
          {authError && (
            <Typography
              variant="body2"
              color="initial"
              align="center"
              sx={{
                fontWeight: "bold",
                color: "red",
              }}
            >
              {t("login.authError")}
            </Typography>
          )}
          {showSubmit ? (
            <Button
              variant="contained"
              fullWidth
              onClick={async () => handleForgotPassword(email, captchaToken)}
              sx={{
                alignSelf: "center",
                fontWeight: "bold",
                backgroundColor: "black",
                color: "white",
                "&:hover": {
                  backgroundColor: "#333333",
                },
                maxWidth: "10rem",
              }}
            >
              {t("login.submit")}
            </Button>
          ) : (
            <ClipLoader color="rgba(0,0,0,.6)" size={35} />
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 2,
            }}
          >
            <Button
              variant="contained"
              fullWidth
              onClick={async () => {
                setLogInWithEmail(true);
                setSignUpWithEmail(false);
                setForgotPassword(false);
                resetEverything();
              }}
              sx={{
                alignSelf: "center",
                fontWeight: "bold",
                backgroundColor: "transparent",
                color: "black",
                borderColor: "black",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  borderColor: "black",
                },
                minWidth: "10rem",
              }}
            >
              {t("login.emailLogin")}
            </Button>
            <Button
              variant="contained"
              fullWidth
              onClick={async () => {
                setSignUpWithEmail(false);
                setLogInWithEmail(false);
                setForgotPassword(false);
                resetEverything();
              }}
              sx={{
                alignSelf: "center",
                fontWeight: "bold",
                backgroundColor: "orange",
                color: "black",
                borderColor: "black",
                "&:hover": {
                  backgroundColor: "rgba(235, 146, 52, 1)",
                  borderColor: "black",
                },
                minWidth: "10rem",
              }}
            >
              {t("login.anotherWay")}
            </Button>
          </Box>
        </Fragment>
      )}

      {signUpWithEmail && (
        <Fragment>
          <TextField
            id="outlined-basic"
            label={t("login.email")}
            variant="outlined"
            value={email}
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyDown}
            InputLabelProps={{
              style: {
                color: email ? "black" : "rgba(0, 0, 0, 0.54)",
              },
            }}
            sx={{
              width: "100%",
              label: {
                color: "black",
              },
              input: {
                color: "black",
              },
              ".MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "black",
                  borderWidth: "2px",
                },
                "&:hover fieldset": {
                  borderColor: "black",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "black",
                },
              },
            }}
          />
          {emailNotValid && (
            <Typography
              variant="body2"
              color="initial"
              align="center"
              sx={{
                fontWeight: "bold",
                color: "red",
              }}
            >
              {t("login.emailCheck")}
            </Typography>
          )}
          {maxAttemptsReached && (
            <Typography
              variant="body2"
              color="initial"
              align="center"
              sx={{
                fontWeight: "bold",
                color: "red",
              }}
            >
              {t("login.maxAttempts")}
            </Typography>
          )}
          <TextField
            id="outlined-basic"
            label={t("login.password")}
            variant="outlined"
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyDown}
            InputLabelProps={{
              style: {
                color: password ? "black" : "rgba(0, 0, 0, 0.54)",
              },
            }}
            sx={{
              width: "100%",
              label: {
                color: "black",
              },
              input: {
                color: "black",
              },
              ".MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "black",
                  borderWidth: "2px",
                },
                "&:hover fieldset": {
                  borderColor: "black",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "black",
                },
              },
            }}
          />
          {passwordTooShort && (
            <Typography
              variant="body2"
              color="initial"
              align="center"
              sx={{
                fontWeight: "bold",
                color: "red",
              }}
            >
              {t("login.passwordCheck")}
            </Typography>
          )}
          <TextField
            id="outlined-basic"
            label={t("login.confirmPassword")}
            variant="outlined"
            value={confirmPassword}
            type="password"
            onChange={(e) => setConfirmPassword(e.target.value)}
            onKeyDown={handleKeyDown}
            InputLabelProps={{
              style: {
                color: confirmPassword ? "black" : "rgba(0, 0, 0, 0.54)",
              },
            }}
            sx={{
              width: "100%",
              label: {
                color: "black",
              },
              input: {
                color: "black",
              },
              ".MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "black",
                  borderWidth: "2px",
                },
                "&:hover fieldset": {
                  borderColor: "black",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "black",
                },
              },
            }}
          />
          {passwordsDontMatch && (
            <Typography
              variant="body2"
              color="initial"
              align="center"
              sx={{
                fontWeight: "bold",
                color: "red",
              }}
            >
              {t("login.passwordMatchCheck")}
            </Typography>
          )}
          {showCaptcha && (
            <Turnstile
              siteKey="0x4AAAAAAAM1j7tv3O9VOfgu"
              onSuccess={(token) => {
                setCaptchaToken(token);
              }}
            />
          )}
          {captchaNotCompleted && (
            <Typography
              variant="body2"
              color="initial"
              align="center"
              sx={{
                fontWeight: "bold",
                color: "red",
              }}
            >
              {t("login.captchaCheck")}
            </Typography>
          )}
          {emailInUse && (
            <Typography
              variant="body2"
              color="initial"
              align="center"
              sx={{
                fontWeight: "bold",
                color: "red",
              }}
            >
              {t("login.emailInUseCheck")}
            </Typography>
          )}
          {authError && (
            <Typography
              variant="body2"
              color="initial"
              align="center"
              sx={{
                fontWeight: "bold",
                color: "red",
              }}
            >
              {t("login.authError")}
            </Typography>
          )}
          {showSubmit ? (
            <Button
              variant="contained"
              fullWidth
              onClick={async () =>
                handleEmailSignUp(email, password, captchaToken)
              }
              sx={{
                alignSelf: "center",
                fontWeight: "bold",
                backgroundColor: "black",
                color: "white",
                "&:hover": {
                  backgroundColor: "#333333",
                },
                maxWidth: "10rem",
              }}
            >
              {t("login.submit")}
            </Button>
          ) : (
            <ClipLoader color="rgba(0,0,0,.6)" size={35} />
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 2,
            }}
          >
            <Button
              variant="contained"
              fullWidth
              onClick={async () => {
                setSignUpWithEmail(false);
                setLogInWithEmail(true);
                setForgotPassword(false);
                resetEverything();
              }}
              sx={{
                alignSelf: "center",
                fontWeight: "bold",
                backgroundColor: "transparent",
                color: "black",
                borderColor: "black",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  borderColor: "black",
                },
                minWidth: "10rem",
              }}
            >
              {t("login.emailLogin")}
            </Button>
            <Button
              variant="contained"
              fullWidth
              onClick={async () => {
                setSignUpWithEmail(false);
                setLogInWithEmail(false);
                setForgotPassword(false);
                resetEverything();
              }}
              sx={{
                alignSelf: "center",
                fontWeight: "bold",
                backgroundColor: "orange",
                color: "black",
                borderColor: "black",
                "&:hover": {
                  backgroundColor: "rgba(235, 146, 52, 1)",
                  borderColor: "black",
                },
                minWidth: "10rem",
              }}
            >
              {t("login.anotherWay")}
            </Button>
          </Box>
          <Typography
            variant="body2"
            color="initial"
            align="center"
            fontSize={"0.8rem"}
            sx={{ opacity: "0.7" }}
          >
            <Link
              style={{ cursor: "pointer" }}
              onClick={async () => {
                setSignUpWithEmail(false);
                setLogInWithEmail(false);
                setForgotPassword(true);
                resetEverything();
              }}
            >
              {t("login.forgotPassword")}
            </Link>
          </Typography>
        </Fragment>
      )}
      {forgotSuccess && (
        <Fragment>
          <Typography
            variant="body1"
            color="initial"
            align="center"
            sx={{
              fontWeight: "bold",
              fontSize: "1.2rem",
            }}
          >
            {t("login.activate") +
              (emailUsed ? t("login.emailChanged", { email: emailUsed }) : "")}
          </Typography>
        </Fragment>
      )}
      {signUpSuccess && (
        <Fragment>
          <Typography
            variant="body1"
            color="initial"
            align="center"
            sx={{
              fontWeight: "bold",
              fontSize: "1.2rem",
            }}
          >
            {t("login.activate") +
              (emailUsed ? t("login.emailChanged", { email: emailUsed }) : "")}
          </Typography>
        </Fragment>
      )}
      {!signUpWithEmail &&
        !logInWithEmail &&
        !forgotPassword &&
        !signUpSuccess &&
        !forgotSuccess && (
          <Fragment>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                width: "fit-content",
                maxWidth: "80%",
                minWidth: "70%",
              }}
            >
              <Button
                variant="contained"
                fullWidth
                startIcon={<GoogleIcon />}
                onClick={async () => handleLogin("google")}
                sx={{
                  alignSelf: "center",
                  fontWeight: "bold",
                  backgroundColor: "#DB4437",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#C1351D",
                  },
                }}
              >
                {t("login.google")}
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                width: "fit-content",
                maxWidth: "80%",
                minWidth: "70%",
              }}
            >
              <Button
                variant="contained"
                fullWidth
                startIcon={<FacebookIcon />}
                onClick={async () => handleLogin("facebook")}
                sx={{
                  alignSelf: "center",
                  fontWeight: "bold",
                  backgroundColor: "#4267B2",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#365899",
                  },
                }}
              >
                {t("login.facebook")}
              </Button>
            </Box>
            <Typography
              variant="body2"
              color="initial"
              align="center"
              sx={{
                fontWeight: "bold",
              }}
            >
              {t("login.separator")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                width: "fit-content",
                maxWidth: "80%",
                minWidth: "70%",
              }}
            >
              <Button
                variant="outlined"
                fullWidth
                startIcon={<EmailIcon />}
                onClick={async () => setLogInWithEmail(true)}
                sx={{
                  alignSelf: "center",
                  fontWeight: "bold",
                  backgroundColor: "transparent",
                  color: "black",
                  borderColor: "black",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                    borderColor: "black",
                  },
                }}
              >
                {t("login.emailLogin")}
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                width: "fit-content",
                maxWidth: "80%",
                minWidth: "70%",
              }}
            >
              <Button
                variant="outlined"
                fullWidth
                startIcon={<DraftIcon />}
                onClick={async () => setSignUpWithEmail(true)}
                sx={{
                  alignSelf: "center",
                  fontWeight: "bold",
                  backgroundColor: "transparent",
                  color: "black",
                  borderColor: "black",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                    borderColor: "black",
                  },
                }}
              >
                {t("login.emailSignup")}
              </Button>
            </Box>
          </Fragment>
        )}
      <Typography
        variant="body2"
        color="initial"
        align="center"
        fontSize={"0.8rem"}
        sx={{ opacity: "0.7" }}
      >
        {t("login.accept")}
      </Typography>
    </Box>
  );
};
export default Login;
