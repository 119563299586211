import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const ModalItem = ({
  title,
  primaryContent,
  secondaryContent,
  style,
  children,
  dark,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
        //width: '100%'
      }}
    >
      {title && (
        <Typography
          variant="h6"
          color="initial"
          sx={{ color: dark ? "#fcfcfc" : "rgba(0,0,0,.6)" }}
        >
          {title}
        </Typography>
      )}

      <Box
        sx={{
          p: "1rem",
          borderRadius: "2rem",
          display: "flex",
          //width: '100%',
          maxWidth: "100%",
          justifyContent: "space-between",
          alignItems: "flex-start",
          //border: '1px solid rgb(220,220,220)',
          backgroundColor: dark ? "#09090b" : "rgba(237,235,243,1)",
          //borderTop: '1px solid rgb(220,220,220)',
          //borderBottom: '1px solid rgb(220,220,220)',
          gap: "1rem",
          //flex: 1,
          //flexWrap: 'wrap',
          flex: "1", // agregar esta línea
        }}
      >
        <Typography
          variant="body2"
          color="inherit"
          fontWeight="bold"
          sx={{
            //color: 'rgb(100,100,100)',
            color: dark ? "white" : "#000",
            flexGrow: "1",
            flex: "1", // agregar esta línea
            height: "100%",
          }}
        >
          {primaryContent}
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            flexDirection: "column",
            width: "auto !important",
            height: "100%",
          }}
          style={style}
        >
          {secondaryContent && (
            <Typography
              variant="body1"
              color="inherit"
              sx={{
                color: "rgb(100,100,100)",
              }}
            >
              {secondaryContent}
            </Typography>
          )}
          {children && children}
        </Box>
      </Box>
    </Box>
  );
};

export default ModalItem;
