import TextField from "@mui/material/TextField";

const TextEdit = ({
  title,
  style,
  maxRows,
  value,
  handleChanges,
  disabled,
}) => {
  return (
    <TextField
      InputLabelProps={{
        shrink: true,
      }}
      label={title}
      InputProps={{
        style: style,
      }}
      multiline
      maxRows={maxRows}
      value={value}
      suppressContentEditableWarning={true}
      sx={{
        width: "100%",
        color: "#dcdcdc !important",
        fieldset: {
          borderColor: "hsla(0,0%,100%,.1)",
          transition: "all .2s ease-out",
          "&:hover": {
            borderColor: "red !important",
          },
        },
        input: {
          borderColor: "hsla(0,0%,100%,.1)",
          transition: "all .2s ease-out",
          color: "red",
        },
        "& .MuiInputLabel-root": {
          fontSize: "1.1rem",
        },
      }}
      onChange={handleChanges}
      disabled={disabled}
    />
  );
};

export default TextEdit;
