import React, { useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SessionContext } from "../context/SessionContext";
import { NavbarContext } from "../context/NavbarContext";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import Help from "../components/Help";
import { useTranslation } from "react-i18next";

function LandingFaqs() {
  const { t } = useTranslation();
  const session = useContext(SessionContext);
  const navbar = useContext(NavbarContext);
  const location = useLocation();

  useEffect(() => {
    if (location.state?.scrollToHelp) {
      const element = document.getElementById("faqs");
      element.scrollIntoView();
    }
  }, [location.state]);

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        width: "100%",
      }}
      id={"faqs"}
    >
      <Typography
        variant="h3"
        fontWeight={"bold"}
        sx={{
          color: "#000",
          backgroundColor: "white",
          textAlign: "center",
          py: 5,
          px: 2,
          borderTop: "1px solid #dcdcdc",
        }}
      >
        {t("landing.faqs")}
      </Typography>

      <Box
        sx={{
          width: "100%",
          mt: 5,
        }}
      >
        <Help />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Button
          size="large"
          variant="contained"
          color="secondary"
          fullWidth
          sx={{
            backgroundColor: "black",
            color: "white",
            maxWidth: "20rem",
            fontWeight: "bold",
            my: "5rem",
            fontSize: "1.5rem !important",
          }}
          className="gradient-bg2"
          onClick={() => {
            navbar.setSelectedNavItem("login");
            navbar.setOpenNavModal(true);
          }}
          startIcon={!session?.user && <AutoFixHighIcon />}
        >
          {t("landing.tryNow")}
        </Button>
      </Box>
    </Box>
  );
}

export default LandingFaqs;
