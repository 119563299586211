import React, { useContext, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { SessionContext } from "../context/SessionContext";
import { NavbarContext } from "../context/NavbarContext";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";

function LogIn() {
  const { t } = useTranslation();
  const session = useContext(SessionContext);
  const navbar = useContext(NavbarContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Box
      sx={{
        backgroundImage: {
          lg: "linear-gradient(to right, rgba(255,255,255,1) 30%, rgba(255,255,255,0.7) 60%), url(https://images.unsplash.com/photo-1674673353760-c86fdc093a56?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1932&q=80)",
          xs: "linear-gradient(to bottom, rgba(255, 255, 255,1) 0%, rgba(255, 255, 255,0.7) 100%), url(https://images.unsplash.com/photo-1674673353760-c86fdc093a56?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1932&q=80)",
        },
        backgroundColor: "white",
        backgroundSize: "cover",
        backgroundPosition: "right",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: { xs: "90vw", md: "200vw" },
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <Button
            variant="contained"
            color="primary"
            sx={{
              padding: "16px 32px",
              fontSize: "1.2rem",
              fontWeight: "bold",
            }}
            onClick={() => {
              if (session?.stashedUser) {
                session?.loadUserFromStash();
              } else {
                navbar?.setIsVisible(true);
                navbar?.setSelectedNavItem("login");
                navbar?.setOpenNavModal(true);
              }
            }}
          >
            {t("login.continue")}
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default LogIn;
