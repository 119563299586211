import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ModalItem from "./modalItem";
import TabSelector from "./TabSelector";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { addCommas } from "../utils";

const MyAccount = ({ session, handleClose, handleOpen, defaultCategory }) => {
  const { t } = useTranslation();
  const [category, setCategory] = useState(defaultCategory || 0);

  function timestampzToMonthDayYear(timestampz) {
    const date = new Date(timestampz);
    const month = date.toLocaleString("default", { month: "long" });
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    const formattedDate = `${month} ${day}, ${year}`;
    return formattedDate;
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "center",
        width: { xs: "100%", lg: "25rem" },
        height: "45rem",
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          mb: 2,
        }}
      >
        <Typography variant="h5" color="initial" fontWeight="bold" sx={{}}>
          Account
        </Typography>
        <TabSelector
          options={[
            { name: t("account.general") },
            { name: t("account.usage") },
          ]}
          selectedTab={category}
          setSelectedTab={setCategory}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
        }}
      >
        {category === 0 && (
          <Fragment>
            <ModalItem
              title={t("account.account")}
              primaryContent={"👤 " + session?.user?.email}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleClose();
                  session.logout();
                }}
              >
                {t("account.logout")}
              </Button>
            </ModalItem>
            <ModalItem
              title={t("change.changePassword")}
              primaryContent={t("change.changePasswordSubtext")}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  handleOpen("change-password");
                }}
              >
                {t("change.changePasswordButton")}
              </Button>
            </ModalItem>
            {session?.subStatus &&
            session?.subStatus?.[session?.subStatus?.length - 1].active &&
            session?.subStatus?.[session?.subStatus?.length - 1].id !==
              "free" ? (
              <Fragment>
                <ModalItem
                  title={t("account.billing")}
                  primaryContent={t("account.billingDate")}
                  secondaryContent={timestampzToMonthDayYear(
                    session?.subStatus?.[session?.subStatus?.length - 1]
                      ?.period_end
                  )}
                >
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={() => {
                      window.open(
                        `${process.env.REACT_APP_STRIPE_URL}/p/login/3cs14t9l4deC7tu000`,
                        "_blank"
                      );
                    }}
                  >
                    {t("account.manageBilling")}
                  </Button>
                </ModalItem>
                <ModalItem
                  title={t("account.planDetails")}
                  primaryContent={`${
                    session?.subStatus?.[session?.subStatus?.length - 1]?.plan
                      ?.metadata?.name
                  } (${
                    session?.subStatus?.[session?.subStatus?.length - 1]?.plan
                      ?.metadata?.period
                  }ly)`}
                >
                  <Button
                    variant="contained"
                    color="info"
                    onClick={() => {
                      window.open(
                        `${process.env.REACT_APP_STRIPE_URL}/p/login/3cs14t9l4deC7tu000`,
                        "_blank"
                      );
                    }}
                  >
                    {t("account.change")}
                  </Button>
                </ModalItem>
              </Fragment>
            ) : (
              <ModalItem
                title={t("account.morePhotos")}
                primaryContent={t("account.morePhotosSubtext")}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    handleOpen("more-photos");
                  }}
                >
                  {t("account.subscribeButton")}
                </Button>
              </ModalItem>
            )}
          </Fragment>
        )}
        {category === 1 && (
          <Fragment>
            <Fragment>
              {session?.bundleCreditsAllowed === undefined ||
              session?.bundleCreditsUsed === undefined ? (
                <ModalItem
                  title={t("account.bundleUsage")}
                  primaryContent={t("account.usageError", { name: "bundle" })}
                />
              ) : (
                session?.bundleCreditsAllowed !== 0 && (
                  <ModalItem
                    title={t("account.bundleUsage")}
                    primaryContent={`📷 ${addCommas(
                      Math.max(
                        0,
                        session?.bundleCreditsAllowed -
                          (session?.bundleCreditsUsed || 0)
                      )
                    )}/${addCommas(session?.bundleCreditsAllowed)} ${t(
                      "account.photosRemaining"
                    )}`}
                  />
                )
              )}
            </Fragment>
            {session?.subStatus?.[session?.subStatus?.length - 1].active && (
              <Fragment>
                <ModalItem
                  title={
                    session?.subStatus?.[session?.subStatus?.length - 1]?.plan
                      ?.metadata?.credits === "10"
                      ? t("account.freeUsage")
                      : t("account.planUsage")
                  }
                  primaryContent={
                    session?.subStatus?.[session?.subStatus?.length - 1]?.plan
                      ?.metadata?.credits === undefined ||
                    session?.planCreditsUsed === undefined
                      ? t("account.usageError", { name: "plan" })
                      : `🔥 ${addCommas(
                          Math.max(
                            0,
                            Number(
                              session?.subStatus?.[
                                session?.subStatus?.length - 1
                              ]?.plan?.metadata?.credits
                            ) - (session?.planCreditsUsed || 0)
                          )
                        )}/${addCommas(
                          session?.subStatus?.[session?.subStatus?.length - 1]
                            ?.plan?.metadata?.credits
                        )} ${t("account.photosRemaining")}`
                  }
                />
              </Fragment>
            )}
            <ModalItem
              title={t("account.morePhotos")}
              primaryContent={t("account.morePhotosSubtext")}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  handleOpen("more-photos");
                }}
              >
                {t("account.subscribeButton")}
              </Button>
            </ModalItem>
          </Fragment>
        )}
      </Box>
    </Box>
  );
};
export default MyAccount;
