import React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { useContext } from "react";
import { SessionContext } from "../context/SessionContext";
import { useTranslation } from "react-i18next";

function NotFound() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const session = useContext(SessionContext);

  return (
    <Box
      sx={{
        backgroundImage: {
          lg: "linear-gradient(to right, rgba(255,255,255,1) 30%, rgba(255,255,255,0.7) 60%), url(https://images.unsplash.com/photo-1674673353760-c86fdc093a56?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1932&q=80)",
          xs: "linear-gradient(to bottom, rgba(255, 255, 255,1) 0%, rgba(255, 255, 255,0.7) 100%), url(https://images.unsplash.com/photo-1674673353760-c86fdc093a56?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1932&q=80)",
        },
        backgroundColor: "white",
        backgroundSize: "cover",
        backgroundPosition: "right",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: { xs: "90vw", md: "200vw" },
        }}
      >
        <Typography
          variant="h3"
          color="initial"
          fontWeight="bold"
          sx={{ color: "#000", textAlign: "center" }}
        >
          {t("landing.notFound")}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            session?.user ? navigate("/upload") : navigate("/");
          }}
        >
          {t("landing.backHome")}
        </Button>
      </Box>
    </Box>
  );
}

export default NotFound;
