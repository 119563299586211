import { useContext, Fragment } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import CheckIcon from "@mui/icons-material/Check";
import { SessionContext } from "../context/SessionContext";
import { NavbarContext } from "../context/NavbarContext";
import { useTranslation } from "react-i18next";

export default function BundleItem({
  title,
  quantity,
  subheader,
  price,
  description,
  buttonText,
  highlighted,
  action,
}) {
  const { t } = useTranslation();
  const session = useContext(SessionContext);
  const navbar = useContext(NavbarContext);

  const handleClick = () => {
    localStorage.setItem("goToCheckout", true);
    if (session?.stashedUser) {
      session?.loadUserFromStash();
    } else {
      navbar.setSelectedNavItem(action);
      navbar.setOpenNavModal(true);
    }
  };

  function agregarComas(numero) {
    return numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <Fragment>
      <CssBaseline />

      <Grid item key={title} xs={12} md={4}>
        <Box
          sx={{
            bgcolor: "white",
            width: "100%",
            outline: `3px solid ${highlighted ? "#3535ff" : "lightgray"}`,
            transition: "all 150ms ease-in-out",
            borderRadius: "1rem",
            "&:hover": {
              outline: `${highlighted ? "6px" : "3px"} solid ${
                highlighted ? "#3535ff" : "lightgray"
              }`,
              scale: "1.01",
              cursor: "pointer",
            },
          }}
          onClick={() => handleClick()}
        >
          <Box
            sx={{
              p: "2rem",
            }}
          >
            <Chip
              label={subheader}
              sx={{
                bgcolor: "#dcdcff",
                color: "#3535ff",
                fontWeight: "bold",
                borderRadius: "0.7rem",
                fontSize: "1rem",
                mb: 2,
              }}
            />
            <Typography
              component="h5"
              variant="h5"
              align="left"
              fontWeight={"bold"}
              sx={{
                color: "black",
                mb: 1,
              }}
            >
              {title}
            </Typography>
            <Box>
              <Typography
                component="h3"
                variant="h3"
                align="center"
                fontWeight={"bold"}
                sx={{
                  color: "black",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                {price}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mt: 2,
              }}
            >
              <Typography
                component="p"
                variant="p"
                align="left"
                sx={{
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "1.1rem",
                }}
              >
                {agregarComas(quantity)} {t("landing.photoCredits")}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              bgcolor: highlighted ? "#dcdcff" : "WhiteSmoke",
              minHeight: "5rem",
              borderRadius: "0 0 0.8rem 0.8rem",
              px: 4,
              py: 3,
            }}
          >
            {description.map((feature, index) => (
              <Box
                sx={{
                  display: "flex",
                  gap: "1rem",
                }}
                key={index}
              >
                <CheckIcon color="success" />
                <Typography
                  component="p"
                  variant="body2"
                  align="left"
                  sx={{
                    color: "black",
                  }}
                >
                  {feature}
                </Typography>
              </Box>
            ))}
            <Button
              variant="contained"
              color="info"
              size="large"
              onClick={() => {
                handleClick();
              }}
              sx={{
                bgcolor: "#3535ff",
                color: "white",
                borderRadius: "1rem",
                fontWeight: "bold",
                "&:hover": {
                  bgcolor: "#3535ff",
                },
              }}
            >
              {buttonText}
            </Button>
          </Box>
        </Box>
      </Grid>
    </Fragment>
  );
}
