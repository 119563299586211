import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";

const PhotoViewer = ({ selectedPhoto, blobImages, name }) => {
  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: "100%",
          aspectRatio: { xs: "1 / 1", lg: "16 / 9" },
          marginBottom: "1rem",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: { xs: "100%", md: "calc(100vh - 30rem)" },
          minHeight: "10vh",
        }}
      >
        <img
          src={blobImages[selectedPhoto]}
          alt=""
          style={{
            width: "130%",
            height: "100%",
            position: "absolute",
            filter: "blur(50px) saturate(4)",
            opacity: "0.3",
            userSelect: "none",
            pointerEvents: "none",
          }}
        />
        <Box
          sx={{
            width: "contain",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            borderRadius: "1rem",
            overflow: "hidden",
            transition: "all .2s ease-out",
          }}
        >
          <img
            src={blobImages[selectedPhoto] || process.env.REACT_APP_LOADING_GIF}
            alt=""
            style={{
              objectFit: "cover",
              maxWidth: "100%",
              width: "100%",
              height: "100%",
              borderRadius: "1rem",
              userSelect: "none",
              zIndex: 1,
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          position: "absolute",
          zIndex: "99",
          display: "flex",
          justifyContent: "center", // Centra horizontalmente
          top: "1rem",
        }}
      >
        <Box
          sx={{
            bgcolor: "#121216",
            borderRadius: "1rem",
            p: 1,
            display: "flex",
            gap: "0.3rem",
          }}
        >
          <PhotoSizeSelectActualIcon color="secondary" />
          <Typography
            variant="body1"
            color="initial"
            sx={{
              color: "#dcdcdc",
              fontWeight: "bold",
            }}
          >
            {name}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default PhotoViewer;
