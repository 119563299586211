const StripePlans = ({ userID, isEU, customerSecret }) => {
  return (
    <stripe-pricing-table
      pricing-table-id={
        isEU
          ? process.env.REACT_APP_STRIPE_PLAN_EU_TABLE_ID
          : process.env.REACT_APP_STRIPE_PLAN_TABLE_ID
      }
      publishable-key={process.env.REACT_APP_STRIPE_PUBLIC_KEY}
      client-reference-id={userID}
      customer-session-client-secret={customerSecret}
    ></stripe-pricing-table>
  );
};

const StripeBundles = ({ userID, isEU, customerSecret }) => {
  return (
    <stripe-pricing-table
      pricing-table-id={
        isEU
          ? process.env.REACT_APP_STRIPE_BUNDLE_EU_TABLE_ID
          : process.env.REACT_APP_STRIPE_BUNDLE_TABLE_ID
      }
      publishable-key={process.env.REACT_APP_STRIPE_PUBLIC_KEY}
      client-reference-id={userID}
      customer-session-client-secret={customerSecret}
    ></stripe-pricing-table>
  );
};

export { StripePlans, StripeBundles };
