import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

const ModalComponent = ({ open, handleClose, children, dark, pricing }) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "100%", lg: pricing ? "75%" : "fit-content" },
            height: "fit-content",
            maxHeight: "90%",
            maxWidth: "90%",
            bgcolor: dark ? "#121216" : "rgba(255,255,255,1)",
            borderRadius: "2rem",
            boxShadow: 24,
            p: 4,
            overflowY: "scroll",
            overflowX: "hidden",
            outline: "none",
          }}
        >
          <IconButton
            aria-label="close"
            size="small"
            onClick={handleClose}
            sx={{ position: "absolute", right: 30, top: 30 }}
          >
            <CloseIcon
              fontSize="inherit"
              htmlColor={dark ? "#fcfcfc" : "rgba(0,0,0,0.5)"}
              sx={{ fontSize: "2rem" }}
            />
          </IconButton>
          {children}
        </Box>
      </Fade>
    </Modal>
  );
};

export default ModalComponent;
