import React, { useState, useContext } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import { Box } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Checkbox from "@mui/material/Checkbox";
import { PauseCircleFilled, NotStarted } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import DownloadIcon from "@mui/icons-material/Download";
import { NavbarContext } from "../context/NavbarContext";

const AlbumRow = ({
  album,
  index,
  searchTerm,
  navigate,
  setSearchTerm,
  actions,
  selectedAlbums,
  handleSelectAlbum,
  showAlbumId,
  disableExport,
}) => {
  const { t } = useTranslation();
  const navbar = useContext(NavbarContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const cellBorders = {
    borderTop: "2px solid rgba(110,110,110,0.3) !important",
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let albumName = album?.settings?.name;
  if (showAlbumId) {
    albumName = album?.id;
  } else {
    if (!albumName) {
      albumName = album?.predictions?.prediction?.[0]?.data?.[0]?.[0];
      if (!albumName) {
        albumName = album?.id;
      } else if (album?.photos?.length > 1) {
        albumName += " & more";
      }
    }
  }

  function getTopKeywords(data, numKeywords = 5) {
    let keywordCount = {};

    // Obtener las palabras clave y contar su frecuencia
    data?.forEach((item) => {
      const keywords = item?.data[1];

      if (keywords && keywords?.length > 0) {
        keywords?.forEach((keyword) => {
          keywordCount[keyword] = (keywordCount[keyword] || 0) + 1;
        });
      }
    });

    // Ordenar las palabras clave por frecuencia
    const sortedKeywords = Object.keys(keywordCount).sort((a, b) => {
      return keywordCount[b] - keywordCount[a];
    });

    // Devolver las primeras "numKeywords" palabras clave
    if (sortedKeywords.length > 0) {
      return sortedKeywords.slice(0, numKeywords);
    } else {
      // Si no hay palabras clave repetidas, devolver las primeras del primer objeto
      const firstKeywords = data?.[0]?.data?.[1];

      if (firstKeywords && firstKeywords.length > 0) {
        return firstKeywords.slice(0, numKeywords);
      } else {
        // Si no hay palabras clave en absoluto, devolver null
        return null;
      }
    }
  }

  function tiempoTranscurrido(desde) {
    const fechaDesde = new Date(desde);
    return fechaDesde.toLocaleString(undefined, {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  }

  return (
    <TableRow key={index}>
      <TableCell component="th" scope="row" sx={cellBorders}>
        <Checkbox
          color="default"
          checked={selectedAlbums?.includes(album?.id)}
          onChange={(e) => {
            handleSelectAlbum(album?.id);
          }}
          inputProps={{ "aria-label": "controlled" }}
        />
      </TableCell>
      <TableCell component="th" scope="row" sx={cellBorders}>
        <Box
          sx={{
            color: "unset",
            textDecoration: "unset",
            display: "flex",
            alignItems: "center",
            gap: "0.2rem",
          }}
        >
          <Box
            component="a"
            sx={{
              "&:hover": {
                textDecoration: "underline",
                cursor: "pointer",
              },
              minWidth: { xs: "10rem", md: "20rem" },
            }}
            onClick={() => {
              navigate(`/result/${album?.id}`);
            }}
          >
            {albumName}
          </Box>
          <Box
            component="a"
            sx={{
              marginLeft: "auto",
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => {
              if (disableExport) {
                navbar.setOpenNavModal(true);
                navbar.setSelectedNavItem("subscribe");
              } else {
                navbar.setOpenNavModal(album?.id);
                navbar.setSelectedNavItem("download");
              }
            }}
          >
            <DownloadIcon
              sx={{
                fontSize: "1.5rem",
                color: "#34e5eb",
                "&:hover": {
                  color: "#34a2eb",
                },
              }}
            />
          </Box>
        </Box>
      </TableCell>
      <TableCell align="center" sx={cellBorders}>
        {album?.photos?.length || 0}
      </TableCell>
      <TableCell align="center" sx={cellBorders}>
        {album?.status === 0 || album?.status === 1 ? (
          <GroupWorkIcon color="info" className="album_loading_icon" />
        ) : album?.status === 2 ? (
          <CheckCircleIcon color="success" />
        ) : album?.status === 3 ? (
          <ErrorIcon color="error" />
        ) : album?.status === 4 ? (
          <PauseCircleFilled color="info" />
        ) : (
          <NotStarted color="disabled" />
        )}
      </TableCell>
      <TableCell align="left" sx={{ ...cellBorders, p: 0, width: "40%" }}>
        {!searchTerm
          ? album?.status === 2 &&
            getTopKeywords(album?.predictions?.prediction, 5)?.map(
              (keyword, i) => (
                <Chip
                  label={keyword}
                  size="small"
                  sx={{ mx: "0.1rem", "&:hover": { cursor: "pointer" } }}
                  onClick={() => {
                    setSearchTerm(keyword);
                  }}
                  key={i}
                />
              )
            )
          : album?.coincidence || t("albums.searching")}
      </TableCell>
      <TableCell align="right" sx={{ ...cellBorders, whiteSpace: "nowrap" }}>
        {tiempoTranscurrido(album?.created_at)}
      </TableCell>
      <TableCell sx={cellBorders}>
        <div>
          <IconButton aria-label="delete" onClick={handleClick}>
            <MoreVertIcon htmlColor="gray" />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {actions?.map((action, index) => (
              <MenuItem
                onClick={() => {
                  action?.action([album?.id]);
                  handleClose();
                }}
                key={index}
                disableRipple
                disabled={action?.disabled}
                sx={{ gap: 1 }}
              >
                <action.icon color={action?.color || "default"} />
                {action?.name}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </TableCell>
    </TableRow>
  );
};
export default AlbumRow;
