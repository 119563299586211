import React, { Fragment, useContext, useEffect } from "react";
import { SessionContext } from "../context/SessionContext";
import LandingTop from "../components/LandingTop";
import LandingUse from "../components/LandingUse";
import LandingStats from "../components/LandingStats";
import LandingPricing from "../components/LandingPricing";
import LandingFeatures from "../components/LandingFeatures";
import LandingFaqs from "../components/LandingFaqs";
import LandingExamples from "../components/LandingExamples";
import LandingFooter from "../components/LandingFooter";
import LandingVideo from "../components/LandingVideo";
import LandingSocials from "../components/LandingSocials";
import LandingTestimonials from "../components/LandingTestimonials";
import Upload from "./Upload";
import LanguageSelector from "../components/LanguageSelector";

function Home({ supabase }) {
  const session = useContext(SessionContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return session?.user ? (
    <Upload supabase={supabase} />
  ) : (
    <Fragment>
      <LanguageSelector />
      <LandingTop />
      <LandingExamples />
      <LandingTestimonials />
      <LandingVideo />
      <LandingStats />
      <LandingUse />
      <LandingFeatures />
      <LandingPricing />
      <LandingFaqs />
      <LandingSocials />
      <LandingFooter />
    </Fragment>
  );
}

export default Home;
