import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Typography from "@mui/material/Typography";
import PhotoIcon from "@mui/icons-material/Photo";
import VideocamIcon from "@mui/icons-material/Videocam";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import { Fragment } from "react";

function UploadZone({ loading }) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        justifyContent: "center",
        alignItems: "center",
        border: "3.5px rgba(0,0,0,0.5) dashed",
        borderRadius: "2rem",
        padding: { xs: "2.5rem 4rem", md: "0 4rem" },
        backgroundColor: "rgba(125, 152, 178,0.1)",
        height: "100%",
        "&:hover": {
          backgroundColor: "rgba(125, 152, 178,0.2)",
          cursor: "pointer",
        },
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <Fragment>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            endIcon={<FileUploadIcon />}
            sx={{
              padding: "0.5rem 2rem",
              fontWeight: "bold",
              color: "white",
              backgroundColor: "black",
            }}
          >
            {t("upload.chooseFiles")}
          </Button>
          <Typography variant="body1" color="initial" sx={{ color: "#000" }}>
            {t("upload.dropHere")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              disabled
              startIcon={<PhotoIcon />}
              sx={{
                backgroundColor: "rgba(0,0,0,0.1) !important",
                color: "white !important",
              }}
            >
              {t("upload.photosCap")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              disabled
              startIcon={<VideocamIcon />}
              sx={{
                backgroundColor: "rgba(0,0,0,0.1) !important",
                color: "white !important",
              }}
            >
              {t("upload.videosCap")}
            </Button>
          </Box>
        </Fragment>
      )}
    </Box>
  );
}

export default UploadZone;
