import Box from "@mui/material/Box";
import CarouselItem from "./CarouselItem";

const PhotoCarousel = ({
  links,
  selectedPhoto,
  handleSelect,
  predictions,
  carouselRef,
  generating,
  blobImages,
}) => {
  const handleWheel = (e, i) => {
    let direction;
    if (e.deltaY < 0) {
      handleSelect(i === 0 ? 0 : i - 1);
      direction = 1;
    } else {
      handleSelect(i === links.length - 1 ? links.length - 1 : i + 1);
      direction = -1;
    }
    if (i !== links.length - 1 && i !== 0) {
      carouselRef.current.children[i - direction].scrollIntoView({
        inline: "center",
        block: "nearest",
        behavior: "smooth",
      });
    }
  };

  return (
    <Box
      sx={{
        overflowX: "scroll",
        overflowY: "hidden",
        padding: "1rem 0.5rem 1rem 0.5rem",
      }}
      onWheel={(e) => handleWheel(e, selectedPhoto)}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          minWidth: "fit-content",
          justifyContent: links.length === 1 ? "center" : "flex-start",
        }}
        ref={carouselRef}
      >
        {links.map((link, index) => (
          <CarouselItem
            link={link}
            predictions={predictions}
            index={index}
            selectedPhoto={selectedPhoto}
            handleSelect={handleSelect}
            generating={generating}
            blobImages={blobImages}
            key={index}
          />
        ))}
      </Box>
    </Box>
  );
};

export default PhotoCarousel;
