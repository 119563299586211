import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";

function IframePage({ url, title }) {
  const location = useLocation();

  useEffect(() => {
    const iframe = document.querySelector("iframe[data-tally-src]");
    if (iframe) {
      iframe.src = iframe.dataset.tallySrc;
    }
  }, [location]);

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "white",
      }}
    >
      <iframe
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          border: 0,
          marginTop: "2rem",
        }}
        data-tally-src={url}
        width="100%"
        height="100%"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
        title={title}
      ></iframe>
    </Box>
  );
}

export default IframePage;
