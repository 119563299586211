import React, { Fragment } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

function Affiliates() {
  const { t } = useTranslation();

  const openAffiliateUrl = () => {
    const url = process.env.REACT_APP_AFFILIATE_URL;
    window.open(url, "_blank");
  };

  return (
    <Fragment>
      <Helmet>
        <title>PhotoTag.ai - {t("affiliatesHelmet.headingSubtext")}</title>
        <meta
          name="description"
          content={t("affiliatesHelmet.headingDescription")}
        />
        <meta
          property="og:title"
          content={"PhotoTag.ai - " + t("affiliatesHelmet.headingSubtext")}
        />
        <meta
          property="og:description"
          content={t("affiliatesHelmet.headingDescription")}
        />
        <meta
          property="og:image"
          content="https://login.phototag.ai/storage/v1/object/public/assets/thumbnail.jpg"
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:site_name" content="PhotoTag.ai" />
        <meta property="og:type" content="website" />
        <meta
          name="twitter:title"
          content={"PhotoTag.ai - " + t("affiliatesHelmet.headingSubtext")}
        />
        <meta
          name="twitter:description"
          content={t("affiliatesHelmet.headingDescription")}
        />
        <meta
          name="twitter:image"
          content="https://login.phototag.ai/storage/v1/object/public/assets/thumbnail.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "${window.location.href}"
              },
              "headline": "PhotoTag.ai - ${t(
                "affiliatesHelmet.headingSubtext"
              )}",
              "description": "${t("affiliatesHelmet.headingDescription")}",
              "image": "https://login.phototag.ai/storage/v1/object/public/assets/thumbnail.jpg",
              "publisher": {
                "@type": "Organization",
                "name": "PhotoTag.ai",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://phototag.ai/assets/logo.png"
                }
              }
            }
          `}
        </script>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Box
        sx={{
          backgroundImage: {
            lg: "linear-gradient(to right, rgba(255,255,255,1) 30%, rgba(255,255,255,0.7) 60%), url(https://images.unsplash.com/photo-1674673353760-c86fdc093a56?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1932&q=80)",
            xs: "linear-gradient(to bottom, rgba(255, 255, 255,1) 0%, rgba(255, 255, 255,0.7) 100%), url(https://images.unsplash.com/photo-1674673353760-c86fdc093a56?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1932&q=80)",
          },
          backgroundColor: "white",
          backgroundSize: "cover",
          backgroundPosition: "right",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: { xs: "90vw", md: "200vw" },
          }}
        >
          <Typography
            variant="h2"
            fontWeight="bold"
            sx={{ color: "#000", textAlign: "center" }}
          >
            {t("affiliates.header")}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: "#000",
              maxWidth: "60%",
              textAlign: "center",
              mb: 2,
            }}
          >
            <span style={{ fontWeight: "bold" }}>
              {t("affiliates.subtext1")}
            </span>{" "}
            {t("affiliates.subtext2")}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={openAffiliateUrl}
            sx={{
              padding: "16px 32px",
              fontSize: "1.2rem",
              fontWeight: "bold",
            }}
          >
            {t("affiliates.join")}
          </Button>
        </Box>
      </Box>
    </Fragment>
  );
}

export default Affiliates;
