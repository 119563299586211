import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Badge from "@mui/material/Badge";
import { Fragment } from "react";

const TabSelector = ({ setSelectedTab, selectedTab, options, dark }) => {
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Tabs
      value={selectedTab}
      onChange={handleChange}
      aria-label="simple-tabpanel"
      sx={{
        backgroundColor: `rgba(${dark ? "0,0,0,0.5" : "237,235,243,1"})`,
        borderRadius: "2rem",
        p: 1,
      }}
      TabIndicatorProps={{
        style: {
          opacity: "0",
        },
      }}
    >
      {options.map((p, index) => (
        <Tab
          label={`${p.name} ${p.chip ? p?.chip : ""}`}
          icon={p.icon && <p.icon fontSize="small" />}
          id={`simple-tab-${index}`}
          key={p.name}
          aria-controls={`simple-tabpanel-${index}`}
          sx={{
            color: dark ? "#fff" : "black",
            fontWeight: "bold",
            borderRadius: "2rem",
            backgroundColor: "transparent",
            "&.Mui-selected": {
              backgroundColor: "rgba(255,0,0,0.15)",

              transition: "background-color 300ms linear",
            },
          }}
        />
      ))}
    </Tabs>
  );
};
export default TabSelector;
