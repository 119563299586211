import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { SessionContext } from "../context/SessionContext";
import { useTranslation } from "react-i18next";
import posthog from "posthog-js";

function Thanks({ supabase }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const session = useContext(SessionContext);

  useEffect(() => {
    if (supabase && session?.user?.id && session?.user?.email) {
      // Check if the current user has a subscription or bundle for their ID created within the last 10 minutes and
      // an affiliate cookie set. If so, report conversion to Rewardful. Rewardful will only mark the conversion if
      // a Stripe customer has been created for this user in the last 24 hours and has an affiliate cookie set.
      const checkPurchase = async () => {
        const { data: subscriptions } = await supabase
          .from("subscriptions")
          .select("*")
          .eq("user_id", session?.user?.id)
          .gte(
            "created_at",
            new Date(Date.now() - 10 * 60 * 1000).toISOString()
          )
          .limit(1);
        const { data: bundles } = await supabase
          .from("bundles")
          .select("*")
          .eq("user_id", session?.user?.id)
          .gte(
            "created_at",
            new Date(Date.now() - 10 * 60 * 1000).toISOString()
          )
          .limit(1);
        const purchase =
          (subscriptions && subscriptions?.length > 0) ||
          (bundles && bundles?.length > 0);
        if (window.Rewardful.referral && purchase) {
          window.rewardful("convert", { email: session?.user?.email });
        }
      };
      checkPurchase();
    }
  }, [session?.user, supabase]);

  useEffect(() => {
    if (!session) return;

    let user = session?.user;
    if (!user) {
      user = session?.stashedUser;
      if (!user) return;
    }

    posthog.capture("thanks_page_viewed", {
      user_id: user?.id,
      created: user?.created_at,
      last_sign_in: user?.last_sign_in_at,
      provider: user?.app_metadata?.provider,
      language: i18n.language,
    });
  }, [session, i18n.language]);

  return (
    <Box
      sx={{
        backgroundImage: {
          lg: "linear-gradient(to right, rgba(255,255,255,1) 30%, rgba(255,255,255,0.7) 60%), url(https://images.unsplash.com/photo-1674673353760-c86fdc093a56?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1932&q=80)",
          xs: "linear-gradient(to bottom, rgba(255, 255, 255,1) 0%, rgba(255, 255, 255,0.7) 100%), url(https://images.unsplash.com/photo-1674673353760-c86fdc093a56?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1932&q=80)",
        },
        backgroundColor: "white",
        backgroundSize: "cover",
        backgroundPosition: "right",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: { xs: "90vw", md: "200vw" },
        }}
      >
        <Typography
          variant="h3"
          color="initial"
          fontWeight="bold"
          sx={{ color: "#000", textAlign: "center" }}
        >
          {t("thanks.header")}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/upload")}
        >
          {t("thanks.button")}
        </Button>
      </Box>
    </Box>
  );
}

export default Thanks;
